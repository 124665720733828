'use strict'

import { ApiDeleev, ApiUtils } from '../index'

export default {
  getCrispUrl () {
    return ApiUtils.promiseGet(ApiDeleev, '/support/crisp/authenticate/')
  },
  getConversations () {
    return ApiUtils.promiseGet(ApiDeleev, '/support/operator/sessions/')
  },
  takeConversation () {
    return ApiUtils.promisePost(ApiDeleev, `/support/operator/take/`)
  },
  sendMessage (payload) {
    return ApiUtils.promisePost(ApiDeleev, `/support/operator/send_message/`, payload)
  },
  countWaitingConversations () {
    return ApiUtils.promiseGet(ApiDeleev, '/support/operator/count_waiting/')
  }
}
