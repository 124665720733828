import io from 'socket.io-client'
import DeleevSupportApi from '../api/deleev/support'

export default {
  _crisp: null,
  getCrisp () {
    if (!this._crisp) {
      throw new Error('Crisp not initialized')
    }
    return this._crisp
  },
  async getUrlAndToken () {
    const { url, ...credentials } = await DeleevSupportApi.getCrispUrl()
    const endpoint = new URL(url)
    return { endpoint, ...credentials }
  },
  async connect ({ endpoint, identifier, key, websites_ids: websitesIds }) {
    if (!this._crisp) {
      this._crisp = io(
        endpoint.origin, {
          path: endpoint.pathname || '/',
          transports: ['websocket'],
          timeout: 10000,
          reconnection: true,
          reconnectionDelay: 5000,
          reconnectionDelayMax: 10000,
          randomizationFactor: 0.75
        }
      )

      // // Handle client events
      this._crisp.on('connect', () => {
        // console.log('RTM API connected')
      })

      this._crisp.on('disconnect', () => {
        // console.log('RTM API disconnected')
      })

      this._crisp.on('connect_error', () => {
        // console.error('RTM API connection error', error)
      })

      // Handle IO events
      this._crisp.io.on('reconnect', () => {
        // console.log('RTM API reconnecting...')
      })

      this._crisp.io.on('error', () => {
        // console.error('RTM API error', error)
      })

      this._crisp.on('connect', () => {
        // Authenticate to the RTM API
        this._crisp.emit('authentication', {
          // Configure your Crisp authentication tokens
          username: identifier,
          password: key,
          tier: 'plugin',

          // Subscribe to target event namespaces
          events: [
            'message:request:initiated',
            'session:update_availability',
            'message:send',
            'message:received'
          ],
          rooms: [...websitesIds]
        })
      })

      this._crisp.on('authenticated', () => {
        // console.log('RTM API authenticated')
      })

      this._crisp.on('unauthorized', () => {
        // console.log('RTM API unauthorized (token keypair is invalid)')
      })
    }

    return this._crisp
  }
}
