'use strict'

import Vue from 'vue'
import VueCookies from 'vue-cookies'
import eventEmitter from './event-emitter'
import { ApiDeleevConfig, ApiProxysearchConfig } from './api/index'
import ApiDeleevCenters from './api/deleev/centers'
import ApiDeleevSites from './api/deleev/sites'
import ApiDeleevUsers from './api/deleev/users'
import ApiDeleevCategories from './api/deleev/categories'
import ApiDeleevSuppliers from './api/deleev/suppliers'
import ApiDeleevProducts from './api/deleev/products'
import CrispApi from './api/crisp'
import ConversationRepository from './repositories/conversations'

Vue.use(VueCookies)
Vue.$cookies.config('30d', '/', process.env.NODE_ENV === 'production' ? '.deleev.com' : '')

// 2477 - aurelie.lazzarotto@deleev.com - LAZZAROTTO Aurelie
// 3561 - kamel.hocine@deleev.com - HOCINE Kamel
// 148510 - patrick.donascimento@deleev.com - DO NASCIMENTO Patrick
// 25865 - kevin.penture@deleev.com - KEVIN Penture
const _superUsers = [2477, 3561, 148510, 25865]

export default {
  state: {
    authStatus: '',
    token: '',
    user: {},

    sitesFetched: 'tofetch',
    sites: [],
    sites_indexed: {},
    sortedSites: [],
    default_site: null,

    centersFetched: 'tofetch',
    centers: [],
    centers_indexed: {},
    sortedCenters: [],
    default_center: null,

    categoriesFetched: 'tofetch',
    categories: {},

    suppliersFetch: 'tofetch',
    suppliers: [],
    suppliers_formatted: [],
    suppliers_indexed: {},

    brandsFetched: 'tofetch',
    brands: [],
    brands_formatted: [],
    brands_indexed: {},

    pubnubSubscribed: false,
    channelsFetched: 'tofetch',
    channels: [],

    crispSubscribed: false,
    conversationsFetched: 'tofetch',
    conversations: [],

    lbvConfigsStatus: {},
    lbvConfigs: {}
  },
  getStoredToken () {
    let _token = Vue.$cookies.get('api_token')
    if (_token && _token !== 'undefined') return _token

    // _token = localStorage.getItem('api_token')
    // if (_token && _token !== 'undefined') return _token

    return ''
  },
  destroyToken () {
    this.state.token = ''
    this.state.user = {}
    // localStorage.removeItem('api_token')
    Vue.$cookies.remove('api_token')
    ApiDeleevConfig.removeAuthorizationToken()
    ApiProxysearchConfig.removeAuthorizationToken()
  },
  async checkToken () {
    const token = this.getStoredToken()
    let _status = 'inprogress'
    try {
      const data = await ApiDeleevUsers.checkToken(token)
      if (data && data.is_staff) {
        this.setUser(data, token)
        _status = ''
      } else {
        _status = 'error'
      }
    } catch (e) {
      _status = 'error'
    }
    this.state.authStatus = _status
    eventEmitter.emit('auth:ckecked')
  },
  async login (user) {
    let _status = 'inprogress'
    this.state.authStatus = _status
    try {
      const loginData = await ApiDeleevUsers.login(user)
      if (loginData && loginData.auth_token) {
        const userData = await ApiDeleevUsers.checkToken(loginData.auth_token)
        if (userData && userData.is_staff) {
          this.setUser(userData, loginData.auth_token)
          eventEmitter.emit('auth:success')
          // localStorage.setItem('api_token', loginData.auth_token)
          Vue.$cookies.set('api_token', loginData.auth_token, '30d')
          _status = ''
        }
      } else {
        _status = 'error'
      }
    } catch (e) {
      _status = 'error'
    }
    this.state.authStatus = _status
    eventEmitter.emit('auth:updated')
  },
  async logout () {
    await ApiDeleevUsers.logout()
    this.destroyToken()
    eventEmitter.emit('auth:updated')
  },
  isLogged () {
    return this.state.token && this.state.user.is_staff
  },
  setUser (user, token) {
    this.state.token = token
    this.state.user = user

    ApiDeleevConfig.setAuthorizationToken(token)
    ApiProxysearchConfig.setAuthorizationToken(token)
  },
  getUser () {
    return this.state.user
  },
  userIsSuperAdmin () {
    const userGroup = this.state.user.groups || {}
    return userGroup.is_super_admin || _superUsers.indexOf(this.state.user.id) !== -1
  },
  userIsAdmin () {
    const userGroup = this.state.user.groups || {}
    return userGroup.is_super_admin || userGroup.is_acl_manager
  },
  userIsSupport () {
    const userGroup = this.state.user.groups || {}
    return userGroup.is_super_admin || userGroup.is_support
  },
  userCan (permission) {
    const _userIsSuperAdmin = this.userIsSuperAdmin()
    const _userIsAdmin = this.userIsAdmin()
    const _userIsSupport = this.userIsSupport()
    switch (permission) {
      case 'super-admin':
        return _userIsSuperAdmin
      case 'admin':
        return _userIsAdmin
      case 'support':
        return _userIsSupport
      default:
        return true
    }
  },

  async fetchSites () {
    if (['fetching', 'done'].indexOf(this.state.sitesFetched) !== -1) return

    try {
      this.state.sitesFetched = 'fetching'
      const data = await ApiDeleevSites.getSites()
      if (data.sites) this.state.sites = data.sites
      if (data.sites_indexed) this.state.sites_indexed = data.sites_indexed
      if (data.sortedSites) this.state.sortedSites = data.sortedSites
      this.state.default_site = this.state.sites.find(o => o.is_default) || this.state.sites[0]
      this.state.sitesFetched = 'done'
    } catch (e) {}

    eventEmitter.emit('sites:fetched')
  },
  getSites () {
    return this.state.sites
  },
  getSortedSites () {
    return this.state.sortedSites
  },
  getSite (siteId) {
    if (typeof this.state.sites_indexed[siteId] !== 'undefined') {
      return this.state.sites[this.state.sites_indexed[siteId]]
    }
    return null
  },
  getDefaultSite () {
    return this.state.default_site
  },

  async fetchCenters () {
    if (['fetching', 'done'].indexOf(this.state.centersFetched) !== -1) return

    try {
      this.state.centersFetched = 'fetching'
      const data = await ApiDeleevCenters.getCenters()
      if (data.centers) this.state.centers = data.centers
      if (data.centers_indexed) this.state.centers_indexed = data.centers_indexed
      if (data.sortedCenters) this.state.sortedCenters = data.sortedCenters
      this.state.default_center = this.state.centers.find(o => o.is_default) || this.state.centers[0]
      this.state.centersFetched = 'done'
    } catch (e) {}

    eventEmitter.emit('centers:fetched')
  },
  getCenters () {
    return this.state.centers
  },
  getSortedCenters () {
    return this.state.sortedCenters
  },
  getCenter (centerId) {
    if (typeof this.state.centers_indexed[centerId] !== 'undefined') {
      return this.state.centers[this.state.centers_indexed[centerId]]
    }
    return null
  },
  getDefaultCenter () {
    return this.state.default_center
  },

  async fetchCategories () {
    if (['fetching', 'done'].indexOf(this.state.categoriesFetched) !== -1) return

    try {
      this.state.categoriesFetched = 'fetching'
      let response
      for (const siteId of [1, 9, 11, 1013, 1015]) {
        response = await ApiDeleevCategories.getCategories(siteId)
        this.state.categories[siteId] = { data: [], formatted: [], indexed: {} }
        if (response.categories) this.state.categories[siteId].data = response.categories
        if (response.categories_formatted) this.state.categories[siteId].formatted = response.categories_formatted
        if (response.categories_indexed) this.state.categories[siteId].indexed = response.categories_indexed
      }

      this.state.categoriesFetched = 'done'
    } catch (e) {}

    eventEmitter.emit('categories:fetched')
  },
  getCategories (variant, siteId) {
    siteId = siteId || 1
    return this.state.categories[siteId][variant || 'data']
  },
  getCategory (categoryId, siteId) {
    siteId = siteId || 1
    return typeof this.state.categories[siteId].indexed[categoryId] !== 'undefined' ? this.state.categories[siteId].formatted[this.state.categories[siteId].indexed[categoryId]] : null
  },
  async fetchSuppliers () {
    if (['fetching', 'done'].indexOf(this.state.suppliersFetch) !== -1) return

    try {
      this.state.suppliersFetch = 'fetching'

      const data = await ApiDeleevSuppliers.getSuppliers()
      if (data.suppliers) this.state.suppliers = data.suppliers
      if (data.suppliers_formatted) this.state.suppliers_formatted = data.suppliers_formatted
      if (data.suppliers_indexed) this.state.suppliers_indexed = data.suppliers_indexed
      this.state.suppliersFetch = 'done'
    } catch (e) {}

    eventEmitter.emit('suppliers:fetched')
  },
  getSuppliers (variant) {
    return this.state[variant ? `suppliers_${variant}` : 'suppliers']
  },
  getSupplier (supplierId) {
    if (this.state.suppliers_indexed[supplierId] !== 'undefined') {
      return this.state.suppliers[this.state.suppliers_indexed[supplierId]]
    }
    return null
  },
  async fetchBrands () {
    if (['fetching', 'done'].indexOf(this.state.brandsFetched) !== -1) return

    try {
      this.state.brandsFetched = 'fetching'
      const data = await ApiDeleevProducts.getBrands()
      if (data.brands) this.state.brands = data.brands
      if (data.brands_formatted) this.state.brands_formatted = data.brands_formatted
      if (data.brands_indexed) this.state.brands_indexed = data.brands_indexed
      this.state.brandsFetched = 'done'
    } catch (e) {}

    eventEmitter.emit('brands:fetched')
  },
  getBrands (variant) {
    return this.state[variant ? `brands_${variant}` : 'brands']
  },
  getBrand (brandId) {
    if (typeof this.state.brands_indexed[brandId] !== 'undefined') {
      return this.state.brands_formatted[this.state.brands_indexed[brandId]]
    }
    return null
  },
  getBrandByName (brandName) {
    return this.state.brands_formatted.find(o => o.name === brandName)
  },
  // Crisp utils
  async connectToCrisp () {
    const crispCredentials = await CrispApi.getUrlAndToken()
    const crispClient = await CrispApi.connect(crispCredentials)
    if (!this.state.crispSubscribed) {
      crispClient.on('message:send', (message) => {
        // console.log('received from customer', message)
        ConversationRepository.messageSent(message)
      })
      crispClient.on('message:received', (message) => {
        // console.log('received from operator', message)
        ConversationRepository.messageReceived(message)
      })
      // crispClient.on('session:update_availability', (message) => {
      //   // console.log(message)
      // })
      this.state.crispSubscribed = true
    }
  }
}
