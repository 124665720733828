import DeleevSupportApi from '@/api/deleev/support'
import eventEmitter from '@/event-emitter'

export default {
  _indexedConversations: {},
  sortedConversations: [],
  waitingCount: 0,
  _setLastMessageTimestamp (conversation) {
    conversation.messages.sort((a, b) => a.timestamp - b.timestamp)
    const lastMessage = conversation.messages[conversation.messages.length - 1]
    conversation._lastTimestamp = lastMessage.timestamp
  },
  async refreshIndexedConversations () {
    try {
      const { sessions: conversations } = await DeleevSupportApi.getConversations()
      this._indexedConversations = conversations.reduce((acc, conversation) => {
        this._setLastMessageTimestamp(conversation)
        acc[conversation.session_id] = conversation
        return acc
      }, {})
      this.sortConversations()
      eventEmitter.emit('conversations:updated')
    } catch (error) {
      eventEmitter.emit(
        'app:notify',
        { message: 'Erreur lors de la récupération des conversations', title: 'Erreur' }
      )
    }
  },
  sortConversations () {
    this.sortedConversations = Object.values(this._indexedConversations)
      .toSorted((a, b) => b._lastTimestamp - a._lastTimestamp)
  },
  async countWaitingConversations () {
    const { waiting_conversations: waitingCount } = await DeleevSupportApi.countWaitingConversations()
    this.waitingCount = waitingCount
    eventEmitter.emit('conversations:waiting-count')
  },
  async takeConversation () {
    try {
      const { session_taken: sessionTaken } = await DeleevSupportApi.takeConversation()
      this.countWaitingConversations()
      this._setLastMessageTimestamp(sessionTaken)
      this._indexedConversations[sessionTaken.session_id] = sessionTaken
      this.sortConversations()
      eventEmitter.emit('conversations:updated')
    } catch (error) {
      eventEmitter.emit(
        'app:notify',
        { message: "Erreur lors de l'assignation d'une conversation", title: 'Erreur' }
      )
    }
  },
  async sendMessage (sessionId, payload) {
    await DeleevSupportApi.sendMessage({
      session_id: sessionId,
      ...payload
    })
  },
  messageReceived (message) {
    const session = this._indexedConversations[message.session_id]
    session.messages.push(message)
    this._setLastMessageTimestamp(session)
    this.sortConversations()
    eventEmitter.emit('conversations:updated')
  },
  messageSent (message) {
    const session = this._indexedConversations[message.session_id]
    if (!session) {
      // On attend 2s pour rafraichir les conversations, délai large pour le traitement du webhook
      setTimeout(() => this.countWaitingConversations(), 2000)
    } else {
      session.messages.push(message)
      this._setLastMessageTimestamp(session)
      this.sortConversations()
      eventEmitter.emit('conversations:updated')
    }
  }
}
